<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="用户昵称"
            class="search-input"
            v-model="searchObj.nickname"
          ></el-input>
          <el-input
            placeholder="手机号码"
            class="search-input ml-15"
            v-model="searchObj.phone"
          ></el-input>
          <el-input
            placeholder="奖品名称"
            class="search-input ml-15"
            v-model="searchObj.prizeName"
          ></el-input>
          <!-- <el-select
            placeholder="全部状态"
            class="search-input ml-15"
            v-model="searchObj.status"
          >
            <el-option
              v-for="(item, index) in activity_status"
              :key="index"
              :label="item.status"
              :value="index"
            ></el-option>
          </el-select> -->
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            @selection-change="tableSelect"
          >
            <el-table-column label="会员昵称" prop="nickname"></el-table-column>
            <el-table-column label="会员头像" >
              <template slot-scope="scope">
                <el-avatar
                  :src="scope.row.headPortrait"
                  shape="square"
                  alt="加载失败"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column label="手机号码" prop="phone"></el-table-column>
            <el-table-column
              label="初次参加时间"
              prop="createTime"
            ></el-table-column>
            <el-table-column label="好友助力次数" prop="helpTotalNum"></el-table-column>
            <el-table-column label="兑换奖品" prop="prizeName"></el-table-column>
          </el-table>
          <Pagination :total="total" :pageNum="pageNum" :pageSize="pageSize" :pageSizes="pageSizes" @changePage="search" @syncPageData="syncPageData"/>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import request from "@/utils/request"
import Pagination from "@/components/Pagination/index"
import { getCollectingPersonList } from "@/api/maketing/collecting"
export default {
  name: "collecting_user",
  components: {
    Pagination
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        nickname: "",
        prizeName: "",
        phone: "",
      },
      //活动状态
      activity_status: [
        {
          status: "全部"
        },
        {
          status: "未开始"
        },
        {
          status: "进行中:报名中"
        },
        {
          status: "已结束:报名结束"
        },
        {
          status: "已终止:活动结束"
        }
      ],
      // 优惠券表格数据内容
      tableData: [],
      groupId: "",
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  mounted() {
    let params = this.$route.params
    this.groupId = params.id != undefined ? params.id : sessionStorage.getItem('GROUP_ID')
    if(params.id) sessionStorage.setItem('GROUP_ID', this.groupId)
    if(this.groupId) this.getUserData()
  },
  methods: {
    // 搜索事件
    search() {
      let data = this.searchObj
      this.getUserData(data)
    },
    getUserData(searchData = {}) {
      let data = {
        groupId: this.groupId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      data = Object.assign(data, searchData)
      getCollectingPersonList(data).then(res => {
        this.total = res.data.body.total
        this.tableData = res.data.body.list
      })
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        nickname: "",
        prizeName: "",
        phone: "",
      };
      this.getUserData()
    },
    // 会员多选事件
    tableSelect(e) {
      // // console.log(e);
      this.multiSeletction = e;
    },
    // 优惠券多选事件
    selectCou(e) {
      this.selectedCou = e;
    },
    syncPageData(data) {
      this.pageNum = data.num
      this.pageSize = data.size
    },
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
